<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="ExportFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Exporter
          </b-button>

          <b-button
            variant="outline-primary"
            size="sm"
            class="ml-2"
            @click="ExportAllToFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Tout Exporter
          </b-button>

          <!-- bouton export end -->
        </div>

        <b-card no-body title="" class="mt-1">
          <div class="my-2 mx-1 row">
            <!-- search start -->
            <div class="col-2">
              <b-form-input
                id="filter-input"
                v-model="valueSearch"
                type="search"
                placeholder="Recherche"
                @input="filterValue()"
              >
              </b-form-input>
            </div>
            <!-- search end -->

            <!-- date start -->
            <div class="col-2">
              <b-form-datepicker
                v-model="valueStart"
                placeholder="Date début"
                labelResetButton="Annuler"
                reset-button
                :date-format-options="dateFormatOptions"
                locale="fr"
                @input="filterValue()"
              ></b-form-datepicker>
              <span
                class="text-danger"
                style="font-size: 0.9em; margin-top: 5px; margin-left: 8px"
              >
                {{ errorValueStart }}
              </span>
            </div>

            <div class="col-2">
              <b-form-datepicker
                v-model="valueEnd"
                placeholder="Date de fin"
                labelResetButton="Annuler"
                reset-button
                :date-format-options="dateFormatOptions"
                locale="fr"
                @input="filterValue()"
              ></b-form-datepicker>
            </div>
            <!-- date end -->

            <!-- select importateur start -->
            <b-form-select
              v-model="selectedAssocies_acheteur"
              :options="associes_acheteur_options"
              value-field="name"
              text-field="name"
              class="col-2"
              @input="filterValue()"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  style="color: #b4b7bd; text-align: center"
                  >-- Associés/Acheteurs --</b-form-select-option
                >
              </template>
            </b-form-select>

            <!-- solde start -->
            <div id="solde-input" class="col-2">
              <b-form-input
                v-model="beneficesTotal"
                type="text"
                disabled
                placeholder="Bénéfice"
              >
              </b-form-input>
            </div>

            <div id="solde-input" class="col-2">
              <b-form-input
                v-model="dedomagementsTotal"
                type="text"
                disabled
                placeholder="Solde"
              >
              </b-form-input>
            </div>
            <!-- solde end -->

            <!-- select importateur end -->
          </div>

          <!-- table start -->
          <b-table
            v-model="excelDedomag"
            id="my-table"
            responsive
            :fields="headers"
            :items="dedomagements"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="search"
            :filter-function="filterFunction"
            show-empty
            class="border-bottom"
          >
            <template #cell(benefice)="data">
              {{
                data.item.benefice
                  ? $thousandSeparator(data.item.benefice)
                  : "-----"
              }}
            </template>
            <template #cell(montant_vente)="data">
              {{
                data.item.montant_vente
                  ? $thousandSeparator(data.item.montant_vente)
                  : "-----"
              }}
            </template>
            <template #cell(total_percu)="data">
              {{
                data.item.total_percu
                  ? $thousandSeparator(data.item.total_percu)
                  : "-----"
              }}
            </template>
            <template #cell(dedomagement)="data">
              {{
                data.item.dedomagement
                  ? $thousandSeparator(data.item.dedomagement)
                  : "-----"
              }}
            </template>

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2" v-if="totalRows != 0"> 1 - <span v-if="perPage >= totalRows">{{ totalRows }}</span> 
	                <span v-if="perPage < totalRows"> {{perPage}} </span> sur {{ totalRows }}
                  </span>
              <span class="text-nowrap ml-2" v-if="totalRows == 0"> 0 éléments</span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cleave from "vue-cleave-component";
import { ModelListSelect } from "vue-search-select";
// import { FormWizard, TabContent } from 'vue-form-wizard'
import FormWizard from "@core/components/vue-form-wizard/src/components/FormWizard.vue";
import TabContent from "@core/components/vue-form-wizard/src/components/TabContent.vue";
import "@core/components/vue-form-wizard/dist/vue-form-wizard.min.css";
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BIconPlus,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BCardText,
  BBadge,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BIconArrowClockwise,
  BTooltip,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BIconCircleFill,
  BIconCartPlus,
  VBTooltip,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    Cleave,
    ModelListSelect,
    FormWizard,
    TabContent,
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BIconArrowClockwise,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BBadge,
    BIconCartPlus,
    BFormDatepicker,
    BIconCircleFill,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      etatButtonEdit: true,
      numberFormat: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        delimiter: " ",
      },
      dateFormatOptions: { day: "numeric", month: "numeric", year: "numeric" },
      dialogDedomager: false,
      search: "",

      dedomagements: [],
      selectedAssocies_acheteur: "",
      associes_acheteur_options: [
        {
          label: "ASSOCIÉS",
          options: [],
        },
        {
          label: "ACHETEURS",
          options: [],
        },
      ],
      excelDedomag: [],
      associes: [],
      editedItem: {},
      valueStart: null,
      valueSearch: "",
      valueEnd: null,
      errorValueStart: "",
      hidden: true,
      hiddenEmpty: true,
      isOperating: false,
      hiddenLoader: false,
      perPage: 10,
      currentPage: 1,
      // filterValue: "",
      pageOptions: [10, 25, 50, 100, { value: 1000000, text: "Tout" }],
      headers: [
        { label: "Date d'annulation", key: "updated_at", sortable: true },
        { label: "Clients", key: "client_name", sortable: true },
        { label: "Association", key: "association", sortable: true },
        { label: "Montant vente", key: "montant_vente", sortable: true },
        { label: "Total perçu", key: "total_percu", sortable: true },
        { label: "Total démarcheur", key: "old_montant_demarcheur", sortable: true },
        { label: "Montant dédomagé", key: "dedomagement", sortable: true },
        { label: "Bénéfice", key: "benefice", sortable: true },
        { label: "Observations", key: "observation", sortable: true },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    totalRows() {
      // Set the initial number of items
      return this.associes.length;
    },

    beneficesTotal() {
      // Je vais essayer de passer trois niveaux de filtres, let's-go !!!
      let ben = 0;
      if (!this.dedomagements) this.dedomagements = [];
      let dedomagements = this.dedomagements;

      /*1 - Passer le premier niveau, verifier si une date minimale est founie, et remplacer le contenu
      du tableau par la liste des élements verifiants la condition */
      if (this.convert(this.startDateFilterValue)) {
        dedomagements = dedomagements.filter(
          (operation) =>
            operation.updated_at >= this.convert(this.startDateFilterValue)
        );
      }

      /*2 - Passer le deuxième niveau, verifier si une date manimale est founie, et remplacer le contenu
      du tableau par la liste des élements verifiants la condition */
      if (this.convert(this.endDateFilterValue)) {
        dedomagements = dedomagements.filter(
          (operation) =>
            operation.updated_at <= this.convert(this.endDateFilterValue)
        );
      }

      /*3 - Passer le troisième niveau, verifier si un founisseur est founi, et remplacer le contenu
      du tableau par la liste des élements verifiants la condition */
      if (this.FsrFilterValue) {
        dedomagements = dedomagements.filter(
          (operation) => operation.fournisseur == this.FsrFilterValue
        );
      }

      // Sommer enfin les bénefices du tablaeu final obtenu
      dedomagements.forEach((element) => {
        ben += element.benefice;
      });

      return this.$thousandSeparator(ben);
    },

    dedomagementsTotal() {
      let ded = 0;

      if (!this.dedomagements) this.dedomagements = [];
      let dedomagements = this.dedomagements;
      if (this.FsrFilterValue) {
        dedomagements.forEach((element) => {
          if (this.FsrFilterValue == element.fournisseur) {
            if (element.dedomagement == 0) ded += element.total_percu;
          }
        });
      } else {
        dedomagements.forEach((element) => {
          if (element.dedomagement == 0) ded += element.total_percu;
        });
      }
      return this.$thousandSeparator(ded);
    },
  },
  created() {
    this.associesData();
    this.initialize();
    this.getAssociesAcheteurList();
  },
  methods: {
    etatAdd() {
      this.add = true;
      this.hiddenEmpty = true;
    },

    getAssociesAcheteurList() {
      this.$http
        .get("/associes")
        .then((res) => {
          this.associes_acheteur_options[0].options = res.data.data.reverse();
        })
        .catch((e) => {});

      this.$http
        .get("/acheteurs")
        .then((res) => {
          this.associes_acheteur_options[1].options = res.data.data.reverse();
        })
        .catch((e) => {});
    },
    filterValue(val) {
      this.search = " ";
    },
    dateFilter(start, end, value) {
      this.search = " ";
      if (!start && !end) {
        return true;
      } else if (start && !end) {
        return value >= start;
      } else if (!start && end) {
        return value <= end;
      }
      return value >= start && value <= end;
    },
    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date;
    },
    filterFunction(row) {
      var start = null;
      var end = null;
      if (this.valueStart) {
        var parts = this.valueStart.split("-");
        start = new Date(parts[0], parts[1] - 1, parts[2]);
      }
      if (this.valueEnd) {
        var parts = this.valueEnd.split("-");
        end = new Date(parts[0], parts[1] - 1, parts[2]);
      }
      var value = new Date(this.toDate(row.updated_at));

      if (
        this.selectedAssocies_acheteur != null &&
        this.selectedAssocies_acheteur != "" &&
        this.selectedAssocies_acheteur != row.associe
      ) {
        return false;
      } else if (
        row.associe
          .toString()
          .toLowerCase()
          .indexOf(this.valueSearch.toLowerCase()) == -1 &&
        row.client_name
          .toString()
          .toLowerCase()
          .indexOf(this.valueSearch.toLowerCase()) == -1 &&
        row.montant_vente
          .toString()
          .toLowerCase()
          .indexOf(this.valueSearch.toLowerCase()) == -1 &&
        row.total_percu
          .toString()
          .toLowerCase()
          .indexOf(this.valueSearch.toLowerCase()) == -1 &&
        row.observation
          .toString()
          .toLowerCase()
          .indexOf(this.valueSearch.toLowerCase()) == -1
      ) {
        return false;
      } else {
        return this.dateFilter(start, end, value);
      }
    },
    isNotValid(montant) {
      if (montant) {
        return false;
      }
      return true;
    },

    async associesData() {
      await this.$http.get("/associes").then((response) => {
        this.associes.push(response.data.data);
      });
    },

    async initialize() {
      await this.$http.get("/archive/dedomagements").then((response) => {
        if (response.status) {
          this.hiddenLoader = true;
        }

        this.dedomagements = response.data.data;
        this.hiddenLoader = true;
        this.hidden = false;
        if (this.dedomagements) {
          this.dedomagements.forEach((element) => {
            element.client_name = element.client.name;
          });
        }

        if (this.dedomagements.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }
      });
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    convert(date) {
      if (date) {
        date = new Date(date);
      }
      return this.reformatDate(date);
    },

    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return this.reformatDate(date);
    },

    reformatDate(date) {
      if (date) {
        let annee = date.getFullYear();
        let mois = date.getMonth() + 1;
        let jour = date.getDate();
        date = annee + "-" + mois + "-" + jour;
        return date.toString();
      }
      return Date.parse(date);
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.excelDedomag.forEach((element) => {
        obj = {
          "DATE D'ANNULATION": element.created_at,
          CLIENTS: element.client_name,
          ASSOCIATION: element.association,
          "MONTANT VENTE": element.montant_vente ? element.montant_vente : 0,
          "TOTAL PERÇU": element.total_percu ? element.total_percu : 0,
          "MONTANT DÉDOMAGÉ": element.dedomagement ? element.dedomagement : 0,
          BÉNÉFICE: element.benefice ? element.benefice : 0,
          OBSERVATIONS: element.observation ? element.observation : 0,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Dédomages",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.dedomagements.forEach((element) => {
        obj = {
          "DATE D'ANNULATION": element.created_at,
          CLIENTS: element.client_name,
          ASSOCIATION: element.association,
          "MONTANT VENTE": element.montant_vente ? element.montant_vente : 0,
          "TOTAL PERÇU": element.total_percu ? element.total_percu : 0,
          "MONTANT DÉDOMAGÉ": element.dedomagement ? element.dedomagement : 0,
          BÉNÉFICE: element.benefice ? element.benefice : 0,
          OBSERVATIONS: element.observation ? element.observation : 0,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste Dédomagements",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.b-form-date-controls {
  margin-right: 38%;
}

.vue-form-wizard {
  box-shadow: 0px 0px;
  margin-bottom: 0px !important;
}

.wizard-card-footer {
  height: 20px;
}

.wizard-footer-left .wizard-btn {
  //   color: #27295c !important;
  //   border: 1px solid #27295c !important;
  //   background-color: white !important;
  padding-left: 1px !important;
  padding-top: 1px !important;
  padding-right: 15px !important;
  padding-bottom: 1px !important;
}

.wizard-footer-right .wizard-btn {
  color: #27295c !important;
  border: 1px solid #27295c !important;
  background-color: white !important;
  padding-left: 15px !important;
  padding-top: 1px !important;
  padding-right: 0px !important;
  padding-bottom: 1px !important;
}

.wizard-card-footer {
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .annuler {
    margin-bottom: 5px;
  }

  .blockSearch {
    width: 350px !important;
  }

  .search {
    width: 250px !important;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 800px) {
  /* .annuler {
			margin-bottom: 5px;
		} */

  .form {
    margin-left: 10%;
  }

  .formRow .form-group {
    width: 500px;
  }

  .blockForm {
    margin-left: 0px !important;
  }

  .blockSearch {
    width: 80% !important;
  }

  .search {
    width: 250px !important;
    margin-bottom: 10px;
  }

  .BlockbButton {
    padding-right: 0px !important;
    margin-right: 40px !important;
  }
}

@media screen and (max-width: 1000px) {
  #solde-input {
    margin-left: 20px !important;
  }

  .form {
    margin-left: 12%;
  }

  .fonction {
    width: 520px;
  }

  .BlockbButton {
    padding-right: 130px !important;
  }
}
</style>
